import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormattedTextField from '@/components/formattedTextField';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import currencyFormat from '@/helpers/currencyFormat';
import TipsSection from '@/pages/dashboard/commerce/payment/tipsSection';
import type { ClientCredit, Order } from '@/types/schema';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { round } from 'lodash-es';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ClientCreditAmount( { cancelAmount, confirmTotal, selectedCredits }: {
	cancelAmount,
	confirmTotal,
	selectedCredits: ClientCredit[]
} ) {
	const { t } = useTranslation();
	const invoice = useGraphqlResult<Order>();
	
	const [ tipPercent, setTipPercent ] = useState( 0 );
	const [ dollarTip, setDollarTip ] = useState( 0 );
	const [ showTipOptions, setShowTipOptions ] = useState( true );
	const [ selectedTipOption, setSelectedTipOption ] = useState( 4 );
	
	const creditAmount = selectedCredits.reduce( ( acc, credit ) => acc + ( credit.amount || 0 ), 0 );
	
	const total = invoice.grandTotal || 0;
	const remaining = invoice.grandTotal - ( invoice.paidTotal || 0 );
	const paying = creditAmount > remaining ? remaining : creditAmount;
	const remainingCredit = creditAmount - paying;
	
	const gateway = invoice.gateway || invoice.companyLocation?.gateway;
	const cloverGateway = gateway?.external === 'CLOVER' ? gateway : null;
	const showTips = invoice.company.metadata?.hideTips ? false : invoice.metadata.hasOwnProperty( 'hideTips' )
		? !invoice.metadata?.hideTips
		: !invoice.company.metadata?.hideTips;
	const tipTotal = tipPercent ? paying * tipPercent / 100 : dollarTip;
	
	const finalPayingAmount = round( paying - ( tipPercent ? paying * tipPercent / 100 : dollarTip ), 2 );
	
	return (
		<Fragment>
			<FormattedTextField
				fullWidth
				disabled
				value={finalPayingAmount}
				variant='outlined'
				placeholder='Amount'
			/>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:total' )}/>
				<Typography style={{ fontSize: 18 }}>
					{currencyFormat( total )}
				</Typography>
			</ListItem>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:remaining' )}/>
				<Typography color='warning.main' style={{ fontSize: 18 }}>
					{currencyFormat( remaining )}
				</Typography>
			</ListItem>
			<ListItem disableGutters>
				<ListItemText primary='Remaining Credit'/>
				<Typography style={{ fontSize: 18 }}>
					{currencyFormat( remainingCredit || 0 )}
				</Typography>
			</ListItem>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:tip' )}/>
				<Typography color='success.main' style={{ fontSize: 18 }}>
					{currencyFormat( tipTotal || 0 )}
				</Typography>
			</ListItem>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:paying' )}/>
				<Typography variant='h3'>
					{currencyFormat( paying )}
				</Typography>
			</ListItem>
			<TipsSection
				cloverGateway={cloverGateway}
				paying={paying}
				showTips={showTips}
				showTipOptions={showTipOptions}
				setShowTipOptions={setShowTipOptions}
				tipPercent={tipPercent}
				setTipPercent={setTipPercent}
				dollarTip={dollarTip}
				setDollarTip={setDollarTip}
				selectedTipOption={selectedTipOption}
				setSelectedTipOption={setSelectedTipOption}
			/>
			<Stack spacing={2} direction='row' alignItems='center' mt={2}>
				<AsyncLoadingButton
					variant='outlined'
					startIcon={<ArrowBackIosIcon/>}
					onClick={cancelAmount}>
					Back
				</AsyncLoadingButton>
				<AsyncLoadingButton
					variant='contained'
					color='primary'
					disabled={!finalPayingAmount}
					onClick={async () => confirmTotal( {
						totalPaying: paying,
						tip        : tipPercent * paying / 100,
						dollarTip  : dollarTip || tipPercent * paying / 100,
						finalPayingAmount,
					} )}>
					Continue
				</AsyncLoadingButton>
			</Stack>
		</Fragment>
	);
}
