import axios from 'axios';
import { validate } from 'uuid';
import { ClientCredit, Order } from '../../../types/schema';

export function formatCreditCardNumber( cardNumber: string, addStars = true ): string {
	if ( !cardNumber ) return null;
	// Clean non-digit characters
	const cleanedNumber = cardNumber.replace( /\D/g, '' );
	
	// Check if the length is not equal to 16
	if ( cleanedNumber.length !== 16 ) {
		return null;
	}
	
	// Split the cleaned number into parts
	const parts = [
		cleanedNumber.substring( 0, 4 ),
		cleanedNumber.substring( 4, 8 ),
		addStars ? '****' : cleanedNumber.substring( 8, 12 ),
		cleanedNumber.substring( 12, 16 ),
	];
	
	// Join the parts with space
	return parts.join( ' ' );
}

export async function deductClientCredits(
	selectedCredits: ClientCredit[],
	clientId: string,
	order: Order ) {
	const totalCreditDiscount = selectedCredits.reduce( ( total,
		c ) => total + ( c.amount || 0 ), 0 );
	const remainingTotal = order.grandTotal - ( order.paidTotal || 0 );
	const credit = selectedCredits[ 0 ];
	
	if ( credit?.prepaid ) {
		
		const remainingAmount = totalCreditDiscount < remainingTotal ? 0 : totalCreditDiscount - remainingTotal;
		
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/updateCredits`, {
			inputs: [ {
				id      : credit.id,
				selected: remainingAmount === 0, // If the remaining amount is 0, then the credit is fully used
				amount  : remainingAmount,
				order   : order.id,
				client  : clientId,
				staff   : order.staff?.id || null,
				company : order.company.id,
				prepaid : true,
			} ],
		} );
	} else {
		let remainingCredit;
		if ( totalCreditDiscount > remainingTotal ) {
			remainingCredit = {
				reason    : null,
				amount    : totalCreditDiscount - remainingTotal,
				order     : null,
				client    : clientId,
				selected  : null,
				company   : order.company.id,
				expiration: null,
			};
		}
		
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/updateCredits`, {
			inputs: [ ...selectedCredits.map( ( credit ) => ( {
				id      : validate( credit.id ) ? credit.id : null,
				selected: true,
				amount  : credit.amount,
				order   : order.id,
				client  : clientId,
				staff   : order.staff?.id || null,
				company : order.company.id,
			} ) ), remainingCredit ].filter( Boolean ),
		} );
	}
	
}
