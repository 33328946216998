import PageWrapper from '@/components/page/wrapper';
import { CommercePublicRead } from '@/data/commerce/commerce.graphql';
import PageGraphqlProvider from '@/data/query/pageGraphqlProvider';
import currencyFormat from '@/helpers/currencyFormat';
import ClientCreditAmount from '@/pages/dashboard/commerce/payment/clientCreditAmount';
import ClientPaymentAmount from '@/pages/dashboard/commerce/payment/clientPaymentAmount';
import { sendEmailsAfterPayments, sendTextMessageAfterPayments } from '@/pages/dashboard/commerce/payment/helpers';
import { useFetchCloverSurcharge } from '@/pages/settings/cards';
import { deductClientCredits } from '@/pages/tender/[id]/utils';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { ClientCredit, GatewayBase, Order, Payment } from '@/types/schema';
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { IconButton, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import axios from 'axios';
import { isEmpty, startCase, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentAmount from './amount';
import PaymentDetails from './details';
import PaymentMethod from './method';

export default function PaymentDrawer( { order }: { order: Order } ) {
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	const router = useRouter();
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	
	const [ step, setStep ] = useState( 0 );
	const [ amount, setAmount ] = useState( 0 );
	const [ cardFee, setCardFee ] = useState( 0 );
	const [ tip, setTip ] = useState( 0 );
	const [ dollarTip, setDollarTip ] = useState( 0 );
	const [ finalPayingWithSurcharge, setFinalPayingWithSurcharge ] = useState( 0 );
	const [ selectedCredits, setSelectedCredits ] = useState<ClientCredit[]>( [] );
	const [ selectedClientPayment, setSelectedClientPayment ] = useState<Payment>( null );
	const [ method, setMethod ] = useState<string>( undefined );
	const [ cardId, setCardId ] = useState<string>( undefined );
	const [ cardType, setCardType ] = useState( '' );
	const [ paymentGateway, setPaymentGateway ] = useState<GatewayBase | null>( null );
	// const [ paymentCreationDate, setPaymentCreationDate ] = useState<Date>( new Date() );
	
	const route = router.route.split( '/' )[ 1 ];
	const clientPage = route === 'p' || route === 'client';
	
	const gateway = order?.gateway || order?.companyLocation?.gateway;
	const cloverGateway = gateway?.external === 'CLOVER' ? gateway : null;
	useFetchCloverSurcharge( cloverGateway?.id );
	
	return (
		<PageWrapper
			hideBack
			modalWrapper
			primary={`${startCase( toLower( order?.type ) )} #${order?.metadata?.customNumber || order?.number}`}
			secondary={order?.client
				? order?.client?.name || order?.client?.email || order?.client?.contact
				: ''}
			actions={(
				<IconButton
					sx={{ mb: 2 }}
					size='small'
					onClick={closeModal}>
					<CloseRoundedIcon/>
				</IconButton>
			)}
			// containerProps={{ sx: { overflowY: 'scroll' } }}
			boxProps={{ sx: { overflowY: 'scroll', overflowX: 'hidden', height: '100vh', width: { md: 700 } } }}>
			{!order?.id ? (
				<Typography p={2} color='text.secondary'>No id given</Typography>
			) : (
				<PageGraphqlProvider<Order>
					queryKey='commerce'
					query={CommercePublicRead}
					variables={{ id: order?.id }}
					subscription={{ COMMERCE: order?.id }}>
					{( order ) => (
						<Stepper
							orientation='vertical'
							activeStep={step}
							sx={{ '.MuiPaper-root': { bgcolor: 'background.default' } }}>
							<Step key={0}>
								<StepLabel>
									<Typography variant='h5'>
										{step <= 0 ? t( 'common:select-payment-method' )
											: t( 'commerce:payment-method', {
												method: !method.includes( 'saved-' )
													? startCase( method )
													: t( 'common:saved-card-label' ),
											} )}
									</Typography>
								</StepLabel>
								<StepContent>
									<PaymentMethod
										required={clientPage}
										cardType={cardType}
										setCardType={setCardType}
										setSelectedCredits={setSelectedCredits}
										selectedCredits={selectedCredits}
										selectedClientPayment={selectedClientPayment}
										setSelectedClientPayment={setSelectedClientPayment}
										confirmMethod={async ( {
											paymentMethod,
											paymentGateway,
											cardId,
											creditAmount,
											clientPaymentAmount,
										} ) => {
											setMethod( paymentMethod );
											setPaymentGateway( paymentGateway );
											setCardId( cardId );
											setAmount( creditAmount || clientPaymentAmount || 0 );
											if ( paymentMethod === 'Invoice Credit' || paymentMethod === 'client payment' ) {
												setFinalPayingWithSurcharge( creditAmount || clientPaymentAmount || 0 );
											}
											setStep( 1 );
											
										}}
									/>
								</StepContent>
							</Step>
							<Step key={1}>
								<Fragment>
									<StepLabel>
										<Typography variant='h5'>
											{step <= 1
												? t( 'common:payment-amount' )
												: `${t( 'common:payment' )}: ${currencyFormat( finalPayingWithSurcharge )}`}
										</Typography>
									</StepLabel>
									<StepContent TransitionProps={{ unmountOnExit: false }}>
										{isEmpty( selectedClientPayment ) && isEmpty( selectedCredits ) ? (
											<PaymentAmount
												method={method}
												cardType={cardType}
												cancelAmount={() => setStep( 0 )}
												confirmTotal={( {
													total,
													cardFee,
													tip,
													dollarTip,
													finalPayingWithSurcharge,
												} ) => {
													setAmount( total );
													setCardFee( cardFee );
													setTip( tip );
													setDollarTip( dollarTip );
													setFinalPayingWithSurcharge( finalPayingWithSurcharge );
													setStep( 2 );
												}}
											/>
										) : !isEmpty( selectedClientPayment ) ? (
											<ClientPaymentAmount
												clientPaymentAmount={selectedClientPayment?.amount}
												cancelAmount={() => {
													setStep( 0 );
													setSelectedClientPayment( null );
													setSelectedCredits( [] );
												}}
												confirmTotal={( { total, finalPayingAmount } ) => {
													setAmount( total );
													setFinalPayingWithSurcharge( finalPayingAmount );
													setStep( 2 );
												}}
											/>
										) : !isEmpty( selectedCredits ) ? (
											<ClientCreditAmount
												selectedCredits={selectedCredits}
												cancelAmount={() => {
													setStep( 0 );
													setSelectedClientPayment( [] );
													setSelectedCredits( [] );
												}}
												confirmTotal={( {
													totalPaying,
													tip,
													dollarTip,
													finalPayingAmount,
												} ) => {
													setAmount( finalPayingAmount );
													setTip( tip );
													setDollarTip( dollarTip );
													setFinalPayingWithSurcharge( totalPaying );
													setStep( 2 );
												}}
											/>
										) : null}
									</StepContent>
								</Fragment>
							</Step>
							<Step key={2}>
								<StepLabel>
									<Typography variant='h5'>{t( 'common:confirm' )}</Typography>
								</StepLabel>
								<StepContent>
									<PaymentDetails
										amount={amount}
										cardFee={cardFee}
										cardToken={cardId}
										cardType={cardType}
										paymentGateway={paymentGateway}
										required={clientPage}
										tip={tip}
										dollarTip={dollarTip}
										method={selectedClientPayment?.type || method}
										selectedClientPayment={selectedClientPayment}
										selectedCredits={selectedCredits}
										cancel={() => {
											setStep( method === 'client payment' ? 0 : 1 );
											setSelectedCredits( [] );
										}}
										confirm={async ( { payment, method, credits } ) => {
											if ( method === 'Invoice Credit' && !isEmpty( credits ) ) {
												await deductClientCredits( credits, order?.client?.id, order );
											}
											if ( order?.externalId && method !== 'ach' ) {
												setTimeout( async () => {
													try {
														await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
															id          : order.id,
															keepSameDate: true,
														} );
													} catch {
													}
												}, 3200 );
											}
											
											if ( clientPage && ( method !== 'ach' || payment && !payment?.stripeId?.includes( 'pi' ) ) ) {
												if ( payment?.externalId && order?.externalId ) {
													setTimeout( async () => {
														try {
															const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/getPayment`, {
																orderId  : order.id,
																gatewayId: order.gateway?.id,
																paymentId: payment.externalId,
															} );
															await sendTextMessageAfterPayments( order, payment, data.amount / 100, order?.company, staff, order, order?.client )
																.catch( () => null );
															await sendEmailsAfterPayments( order, payment, data.amount / 100 )
																.catch( () => null );
															enqueueSnackbar( 'Payment completed. Thank you', { variant: 'success' } );
														} catch {
														}
														
													}, 2000 );
												} else {
													await sendTextMessageAfterPayments( order, payment, 0, order?.company, staff, order, order?.client )
														.catch( () => null );
													await sendEmailsAfterPayments( order, payment, 0 ).catch( () => null );
													enqueueSnackbar( 'Payment completed. Thank you', { variant: 'success' } );
													
												}
												
											}
											closeModal();
										}}
										closeDrawer={closeModal}
									/>
								</StepContent>
							</Step>
						</Stepper>
					)}
				</PageGraphqlProvider>
			)}
		</PageWrapper>
	);
}
